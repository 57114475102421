<!--
 * @Description: 
 * @Author: wendan
 * @Date: 2022-07-14 11:02:36
 * @LastEditTime: 2023-03-31 10:43:54
 * @LastEditors: Heisenberg513 yuans0513@gmail.com
 * @Reference: 
-->
<template>
  <div class="supervision">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/dataAnalysis' }">数据分析</el-breadcrumb-item>
        <el-breadcrumb-item>轨迹情况</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>轨迹分析</h4>
        </div>
      </div>
      <el-button style="float: right;" class="searchBtn" @click="onInquire">人员列表</el-button>
      <el-form :model="formData" ref="locusForm" :inline="true">
        <el-form-item label="项目：" prop="projectId">
          <el-select
            v-model="formData.projectId"
            clearable
            filterable
            placeholder="全部"
            @change="changeProject"
          >
            <el-option
              v-for="item in projectMenu"
              :key="item.projectId"
              :label="item.projectName"
              :value="item.projectId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员名称：" prop="nickName">
          <el-input v-model="formData.nickName"></el-input>
        </el-form-item>
        <el-form-item label="设备编号：" prop="deviceNo">
          <el-input class="deviceNo" v-model="formData.deviceNo"></el-input>
        </el-form-item>
        <el-form-item label="时间：" prop="date">
          <el-date-picker
            v-model="formData.date"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button class="searchBtn" @click="handleSearchLocus">查轨迹</el-button>
          <!-- <el-button @click="onReset">重置</el-button> -->
        </el-form-item>
      </el-form>
      <!-- <div class="form-content marginTop">
        <div class="form-item">
          <span class="selectName">项目：</span>
          <el-select
            v-model="formData.projectId"
            clearable
            filterable
            placeholder="全部"
            @change="changeProject"
          >
            <el-option
              v-for="item in projectMenu"
              :key="item.projectId"
              :label="item.projectName"
              :value="item.projectId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <span class="selectName">门店：</span>
          <el-select
            v-model="formData.spaceId"
            clearable
            filterable
            placeholder="全部"
          >
            <el-option
              v-for="item in storeMenu"
              :key="item.spaceId"
              :label="item.spaceName"
              :value="item.spaceId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <span class="selectName">人员名称：</span>
          <el-input v-model="formData.nickName"></el-input>
        </div>
        <div class="form-item">
          <span class="selectName">设备编号：</span>
          <el-input v-model="formData.deviceNo"></el-input>
        </div>
        <div class="select-date form-item">
          <el-date-picker
            v-model="date"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker> -->
      <!-- <el-date-picker
            v-model="date"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker> -->
      <!-- </div>
        <div class="form-item">
          <el-button class="searchBtn" @click="onInquire">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </div>
      </div> -->
      <!-- 放大 -->
      <el-row :gutter="10">
        <ul class="markAnno">
          <li>巡店且有音频</li>
          <li>巡店没有音频</li>
          <li>未巡店</li>
        </ul>
        <div class="toMapBtn" @click="toMap">
          <span class="icon-open"></span>
        </div>
        <!-- <div id="container"></div> -->
        <div id="yzMap1" style="width: 100%; height: 70vh"></div>
        <el-collapse-transition>
          <div class="blobProp" v-show="userList.length != 0">
            <div
              :class="index == selectIndex ? 'info-wrap bg-girl' : 'info-wrap bg-boy'"
              v-for="(item, index) in userList"
              :key="index"
              ref="locusPerson"
              @click.stop="getIcoList(item, index)"
            >
              <div class="top">
                <div class="left">
                  <span :class="item.sex === 0 ? 'img boy' : 'img girl'"></span>
                </div>
                <div class="right">
                  <p class="posi">
                    <span class="name">{{ item.nickName }}</span>
                    <span>{{ item.spaceName }}</span>
                  </p>
                  <!-- <p :class="item.online === 'Y' ? 'status inline' : 'status outline'">
                    <span class="point"></span>
                    {{ item.online === "Y" ? "在线" : "离线" }}
                  </p> -->
                  <div class="control-bottom">
                    <p class="time">设备编号：{{ item.deviceNo }}</p>
                    <i
                      class="el-icon-arrow-down"
                      @click.stop="expand(item.deviceNo, item.userId)"
                    />
                  </div>
                </div>
                {{ item.expandShow }}
              </div>
              <div @click.stop>
                <el-calendar v-if="checkedId === item.userId && checkedNum === item.deviceNo">
                  <template slot="dateCell" slot-scope="{ date, data }">
                    <span
                      :class="calendarData.includes(data.day) ? 'calendar-checked' : ''"
                      @click="getIcoList(item, index, data.day)"
                    >
                      {{ date.getDate() }}
                    </span>
                  </template>
                </el-calendar>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </el-row>
    </div>
  </div>
</template>
<script>
function bgps_gps(bd_lng, bd_lat) {
  var X_PI = (Math.PI * 3000.0) / 180.0;
  var x = bd_lng - 0.0065;
  var y = bd_lat - 0.006;
  var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
  var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
  var gg_lng = z * Math.cos(theta);
  var gg_lat = z * Math.sin(theta);
  return { lng: gg_lng, lat: gg_lat };
}
import MapLoader from '@/plugin/aMap';
import {
  projectList,
  getSpaces,
  getIocusUserList,
  getIcoList,
  getMarkerList,
  getTrackDates,
  getAudioDayInfo
} from '@/api/dataAnalysis/staff';
export default {
  computed: {},
  components: {},
  data() {
    return {
      mapText: [],
      checkedId: null,
      checkedNum: null,
      calendarData: [],
      selectIndex: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      thisUserNickName: '',
      markerList: [],
      center: [121.499718, 31.239703],
      icolist: [],
      map: null,
      userList: [],
      // date: [],
      date: '',
      projectMenu: [],
      storeMenu: [],
      formData: {
        spaceId: '',
        projectId: '',
        nickName: '',
        deviceNo: '',
        date: ''
      },
      // 门店撒点
      markerList: [],
      changeUserId: '',
      updateDate: ''
    };
  },
  mounted() {
    MapLoader().then(
      AMap => {
        this.map = new AMap.Map('yzMap1', {
          zoom: 15,
          resizeEnable: true
        });
        this.initMap();
        // ....你的操作
      },
      e => {}
    );

    projectList().then(res => {
      this.projectMenu = res.data;
    });
    getSpaces().then(res => {
      this.storeMenu = res.data;
    });
  },
  methods: {
    async expand(num, id) {
      this.checkedId = this.checkedId ? '' : id;
      this.checkedNum = this.checkedNum ? '' : num;
      const res = await getTrackDates({ deviceNo: num, userId: id });
      this.calendarData = res.code === 200 && res.data;
    },
    toMap() {
      let data = this.formData.date;
      if (typeof data == 'undefined' || data == null || data == '') {
        this.$message('请选择时间');
        //   } else if (
        //     this.changeUserId == "" ||
        //     this.changeUserId == null ||
        //     typeof this.changeUserId == "undefined"
        //   ) {
        //     this.$message("请选择人员");
      } else {
        let icoData = {
          startDate: data,
          endDate: data,
          spaceId: this.formData.spaceId,
          projectId: this.formData.projectId,
          nickName: this.formData.nickName,
          deviceNo: this.formData.deviceNo
        };
        let routeUrl = this.$router.resolve({
          path: '/icumagnify',
          query: icoData
        });
        window.open(routeUrl.href, '_blank');
      }
    },
    addMark() {
      let that = this;
      let arr;
      if (that.icolist.length === 0) {
        arr = [];
      } else {
        arr = that.icolist.map(i => {
          let p = [];
          p.push(parseFloat(i.longitude));
          p.push(parseFloat(i.latitude));
          return p;
        });
      }
      if (window.pathSimplifierIns) {
        pathSimplifierIns.setData([]);
      }
      // 用户轨迹
      AMapUI.load(['ui/misc/PathSimplifier', 'lib/$'], function(PathSimplifier, $) {
        if (!PathSimplifier.supportCanvas) {
          alert('当前环境不支持 Canvas！');
          return;
        }
        let defaultRenderOptions = {
          renderAllPointsIfNumberBelow: 1000,
          pathLineStyle: {
            lineWidth: 7,
            borderWidth: 1,
            strokeStyle: '#479eeb',
            lineWidth: 7
          },
          pathLineHoverStyle: {
            lineWidth: 7
          },
          keyPointStyle: {
            radius: 5
          },
          hoverTitleStyle: {
            position: 'top',
            classNames: 'simplifierStyle'
          }
        };
        let pathSimplifierIns = new PathSimplifier({
          zIndex: 100,
          //autoSetFitView:false,
          map: that.map, //所属的地图实例

          getPath: function(pathData, pathIndex) {
            return pathData.path;
          },
          getHoverTitle: function(pathData, pathIndex, pointIndex) {
            let item = that.icolist[pointIndex];
            let content;

            if (pointIndex >= 0) {
              content = `
               <div class="mark-wrap">
                <p class="info">定位时间：${item.createDate}</p>
                <p class="info">地点：${item.placeName}</p>
                <p class="info">详细地址：${item.address}</p>
                <p class="info">经纬度：${item.longitude},${item.latitude}</p>
              </div>`;
              return content;
            }
            content = `
             <div class="mark-wrap">
              <p class="info">用户：${pathData.name}</p>
            <p class="info">，轨迹点数量：${pathData.path.length}</p>
            </div>
          `;
            return pathData.name + '，轨迹点数量' + pathData.path.length;
          },
          renderOptions: defaultRenderOptions
        });

        window.pathSimplifierIns = pathSimplifierIns;

        //设置数据
        pathSimplifierIns.setData([
          {
            name: that.thisUserNickName + '的行动轨迹',
            path: arr
          }
        ]);

        //对第一条线路（即索引 0）创建一个巡航器
        let navg1 = pathSimplifierIns.createPathNavigator(0, {
          loop: true, //循环播放
          speed: 1000 //巡航速度，单位千米/小时
        });

        navg1.start();
      });
      // 间隔30个点标注
      this.map.remove(this.mapText);
      this.mapText = [];
      this.icolist.forEach((item, index) => {
        if (index % 30 === 0 || index + 1 === this.icolist.length) {
          let text = new AMap.Text({
            text: `${item.createDate}`,
            position: new AMap.LngLat(item.longitude, item.latitude),
            zIndex: 90
          });
          this.mapText.push(text);
        }
      });
      this.map.add(this.mapText);
    },
    handleSearchLocus() {
      if (this.formData.deviceNo === '' || this.formData.date === '') {
        this.$message.warning('请输入设备编号和日期');
        return;
      }
      this.updateDate = '';
      this.getIcoList();
    },
    async getIcoList(item, index, date) {
      // if (e.currentTarget === e.target) {
      //   console.log(1);
      // }
      this.icolist = [];
      // this.markerList = [];
      this.changeUserId = item?.deviceNo || this.formData.deviceNo;
      let data = date || this.formData.date;
      this.updateDate = date;
      if (typeof data == 'undefined' || data == null || data == '') {
        this.$message('请选择时间');
      } else {
        let icoData = {
          startDate: data,
          endDate: data,
          deviceNo: item?.deviceNo || this.formData.deviceNo
        };
        this.selectIndex = index;
        this.thisUserNickName = item?.nickName || this.formData.nickName;
        await getMarkerList(icoData).then(res => {
          if (res.data.length == 0) {
            this.$message('暂无门店信息');
            this.markerList = [];
            this.map.clearMap();
            return false;
          } else {
            this.markerList = res.data;
          }
        });
        await getIcoList(icoData).then(res => {
          if (res.data.length == 0) {
            if (window.pathSimplifierIns) {
              //通过该方法清空上次传入的轨迹
              pathSimplifierIns.setData([]);
            }
            this.$message('暂无轨迹信息');
            this.icolist = [];
            return false;
          }
          this.icolist = res.data;
          // this.initMap();
          this.addMark();
          this.addIcon(item);
          //   for (var i = 0; i < _this.markerList.length; i++) {
          //     _this.addIcon(
          //       _this.markerList[i].longitude,
          //       _this.markerList[i].latitude,
          //       _this.markerList[i].spaceName,
          //       _this.markerList[i].spaceNo,
          //       _this.markerList[i].spacePath,
          //       _this.markerList[i].spaceId
          //     );
          //   }
        });
      }
    },
    addIcon(item) {
      var _this = this;
      for (var i = 0; i < _this.markerList.length; i++) {
        var viaMarker = new AMap.Marker({
          position: new AMap.LngLat(_this.markerList[i].longitude, _this.markerList[i].latitude)
        });

        var circle = new AMap.Circle({
          // center: [left, right], isPrimary
          center: [_this.markerList[i].longitude, _this.markerList[i].latitude],
          radius: 100, //半径
          // strokeColor: "#479eeb",
          strokeColor:
            _this.markerList[i].isAudio === 'Y'
              ? '#13ce66'
              : _this.markerList[i].isAudio === 'N' && _this.markerList[i].isPass === 'Y'
              ? '#ffde00'
              : '#ff0000',
          strokeOpacity: 1,
          fillOpacity: 0.2,
          strokeStyle: 'solid',
          // 线样式还支持 'dashed'
          fillColor: 'transparent',
          zIndex: 50
        });

        var infoWindow = new AMap.InfoWindow({
          offset: new AMap.Pixel(0, -70)
        });
        viaMarker.content =
          `<div class='textBox'><ul><li><p>门店编号：</p><span>` +
          _this.markerList[i].spaceNo +
          '</span></li><li><p>门店名称：</p><span>' +
          _this.markerList[i].spaceName +
          '</span></li><li><p>门店地址：</p><span>' +
          _this.markerList[i].spacePath +
          `</span></li>
          <li>${
            _this.markerList[i].isAudio === 'Y'
              ? '<a onclick="handleViewAudio()">点击查看音频</a> '
              : ''
          }</li></ul></div>`;
        viaMarker.on('click', markerClick);
        infoWindow.close();
        function markerClick(e) {
          infoWindow.setContent(e.target.content);
          infoWindow.open(_this.map, e.target.getPosition());
        }
        window.handleViewAudio = () => {
          getAudioDayInfo({
            userId: item?.userId || '',
            deviceNo: item?.deviceNo || this.formData.deviceNo,
            audioDate: this.updateDate || this.formData.date
          }).then(res => {
            res.code === 200 &&
              _this.$router.push({
                path: '/wordsAnalyze/audioRecord/audioDetail',
                query: {
                  audioDayId: res.data.audioDayId,
                  audioDate: _this.formData.date
                }
              });
          });
        };
        // _this.map.clearMap();
        _this.map.remove([viaMarker]);
        _this.map.remove([circle]);
        // circle.setMap(_this.map);
        _this.map.add([viaMarker]);
        _this.map.add([circle]);
        // 缩放地图到合适的视野级别
        _this.map.setFitView([circle]);
      }
    },
    initMap() {
      let self = this;
      let aMapScript = document.createElement('script');
      aMapScript.setAttribute(
        'src',
        'https://webapi.amap.com/maps?v=1.4.13&key=ec85d3648154874552835438ac6a02b2&plugin=AMap.PlaceSearch,AMap.AdvancedInfoWindow'
      );
      document.head.appendChild(aMapScript);
      let aMapScript1 = document.createElement('script');
      aMapScript1.setAttribute('src', 'https://webapi.amap.com/ui/1.1/main.js?v=1.1.1');
      document.head.appendChild(aMapScript1);
      aMapScript.onload = function() {
        let center = bgps_gps(self.center[0], self.center[1]);
        let map = new AMap.Map('yzMap1', {
          center: [center.lng, center.lat], //地区位置
          resizeEnable: true,
          zoom: 15,
          isHotspot: false
        });
        //        AMap.plugin([
        //     'AMap.ToolBar',
        // ], function(){
        //     // 在图面添加工具条控件, 工具条控件只有缩放功能
        //     map.addControl(new AMap.ToolBar());
        // });
        self.map = map;
        var infoWindow;
        AMap.service('AMap.AdvancedInfoWindow', function() {
          infoWindow = new AMap.AdvancedInfoWindow({});
        });
        // var placeSearch = new AMap.PlaceSearch(); //构造地点查询类
        AMap.service('AMap.PlaceSearch', function() {
          var placeSearch = new AMap.PlaceSearch({
            // city 指定搜索所在城市，支持传入格式有：城市名、citycode和adcode
            city: '010'
          });
        });
        //回调函数
        function placeSearch_CallBack() {
          //infoWindow.open(map, result.lnglat);
          //var poiArr = data.poiList.pois;
          var location = [121.740318, 31.054126];
          infoWindow.setContent(createContent());
          infoWindow.open(self.map, location);
        }
      };
    },
    changeProject(val) {
      if (val) {
        this.formData.spaceId = '';
      }
      getSpaces(val).then(res => {
        this.storeMenu = res.data;
      });
    },
    onReset() {
      this.$refs.locusForm.resetFields();
      // this.formData.projectId = '';
      // this.formData.spaceId = '';
      // this.formData.nickName = '';
      // this.formData.deviceNo = '';
      // this.formData.date = '';
    },
    onInquire() {
      if (this.markerList) {
        this.map.remove(this.markerList);
        this.markerList = [];
      }
      this.$refs.locusForm.validate(valid => {
        valid &&
          getIocusUserList(this.formData).then(res => {
            this.userList = res.data;
          });
      });
    },
    getProList() {
      projectList().then(res => {
        this.projectMenu = res.data;
      });
    },
    getSpaceList(id) {
      getSpaces(id).then(res => {
        this.spaceTypeMenu = res.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
// 撒点弹框
/deep/.amap-info-content {
  padding: 0;
}
/deep/.textBox {
  min-width: 400px;
  border: 2px solid #ffffff;
  box-shadow: 0px 15px 20px rgba(118, 139, 175, 0.4),
    inset 0px 0px 30px 10px rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(9px);
  border-radius: 16px;
  padding: 20px;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  li {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    line-height: 30px;
    p {
      margin: 0;
    }
    span {
      display: inline-block;
    }
    a {
      color: blue;
      cursor: pointer;
    }
  }
}
.deviceNo {
  &::after {
    content: '*';
    position: absolute;
    top: 0;
    right: 10px;
    color: #f84a22;
  }
}
.el-date-editor {
  &::after {
    content: '*';
    position: absolute;
    top: 0;
    right: 10px;
    color: #f84a22;
  }
}
.supervision {
  margin: 2vh 1.5vw;

  .marginTop {
    margin-top: 2vh;
  }

  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;

    .form-content {
      display: flex;
      flex-wrap: wrap;

      .form-item {
        margin-right: 1.5625vw;
        margin-bottom: 1.7vh;

        .el-select {
          width: 10.9375vw;
        }

        .el-input {
          width: 10.9375vw;
          background-color: rgba(29, 86, 240, 0.507);
        }

        .el-cascader {
          width: 10.9375vw;
        }
      }

      .select-date {
        .el-input__inner {
          width: 13vw;
          padding-right: 0;
        }
      }
    }

    .export {
      float: right;
    }

    // /deep/.el-select {
    //   width: 74%;
    // }
    // /deep/.el-cascader {
    //   width: 74%;
    // }
    .title {
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;

        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }

        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }

    .fr {
      float: right;
      width: auto;
      margin-left: 0;
    }

    .selectName {
      // width: 26%;
      font-size: 14px;
      display: inline-block;
      color: $fontColor;
      // text-align: left;
      // text-align-last: justify;
    }

    .indent2 {
      padding-right: 2em;
    }

    .parent {
      position: relative;

      .searchInput {
        position: absolute;
        right: 0;
        height: 100%;
        text-align: center;
        background: $baseColor;
        width: 2vw;
        right: 0px;
        top: 0;
        border-radius: 0 5px 5px 0;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .searchBtn {
      color: $white;
      background-color: $baseColor;
    }
  }
}

.blobProp {
  padding: 31px 26px 45px 21px;
  position: absolute;
  bottom: 6px;
  right: 21px;
  width: 400px;
  z-index: 999;
  background: rgba(255, 255, 255, 0.8);
  mix-blend-mode: normal;
  box-shadow: 0px 20px 26px rgba(0, 0, 0, 0.05), 0px 8px 9px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(30px);
  border-radius: 16px;
  font-family: 'PingFang SC';
  font-style: normal;
  height: 50vh;
  min-width: 314px;
  overflow: hidden;
  overflow-y: scroll;

  .info-wrap {
    background: rgba(24, 144, 255, 0.1);
    border-radius: 20px;
    padding: 15px;
    cursor: pointer;
    margin: 10px;
    .top {
      display: flex;
    }

    .left {
      .img {
        display: inline-block;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        border-radius: 11px;
        margin-top: 15px;
      }

      .boy {
        background: rgba(24, 144, 255, 0.2);
        box-shadow: 0px 4px 12px rgba(145, 154, 163, 0.25);
        background: url('../../../../assets/img/ic_user0.png') no-repeat;
        background-size: 100% 100%;
      }

      .girl {
        background: rgba(255, 189, 123, 0.3);
        box-shadow: 0px 4px 12px rgba(145, 154, 163, 0.25);
        background: url('../../../../assets/img/ic_user1.png') no-repeat;
        background-size: 100% 100%;
      }
    }

    .right {
      flex: 1;
      margin-left: 10px;
      vertical-align: middle;

      .posi {
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        font-size: 16px;
        margin-top: 10px;

        .name {
          margin-right: 20px;
        }
      }
      .control-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
      }
    }

    ::v-deep .el-calendar {
      margin-top: 10px;
    }
    .calendar-checked {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &::after {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        background-color: #f84a22;
        border-radius: 50%;
      }
    }

    ::v-deep .el-calendar-table .el-calendar-day {
      width: 40px;
      height: 40px;
    }
  }

  .bg-boy {
    background: rgba(24, 144, 255, 0.1);
  }

  .bg-girl {
    background: rgba(255, 170, 100, 0.1);
  }

  .status {
    padding: 0;
    margin: 0;
    margin: 10px 0;
    font-size: 12px;

    font-weight: 400;
    font-size: 14px;
  }

  .inline {
    color: #23b899;
  }

  .outline {
    color: #f84a22;
  }

  .time {
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: gray;
    /* margin-top: 10px; */
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .no-data {
    text-align: center;
    margin-top: 50%;
    color: #b7b7b7;
  }
}
.markAnno {
  position: absolute;
  top: 5px;
  left: 25px;
  z-index: 999;
  padding: 10px 20px;
  background-color: #ffffff;
  border-radius: 6px;
  li {
    list-style: none;
  }
  li {
    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 10px;
      border-radius: 50%;
      background-color: #13ce66;
    }
  }
  li:nth-child(2) {
    &::before {
      background-color: #ffde00;
    }
  }
  li:nth-child(3) {
    &::before {
      background-color: #ff0000;
    }
  }
}
.toMapBtn {
  position: absolute;
  bottom: 5px;
  left: 5px;
  border-radius: 4px;
  z-index: 999;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
}

.icon-open {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: url('../../../../assets/img/ic_open.png') no-repeat;
  background-size: 100% 100%;
}

::v-deep .amap-logo {
  display: none;
  opacity: 0 !important;
}

::v-deep .amap-copyright {
  opacity: 0;
}
</style>
